import React from 'react'
import {styled} from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Typography from '@mui/material/Typography'
import MUIAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import ArrowBack from '@mui/icons-material/ArrowBack'
import Close from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import {postMessage} from '../../../handlers/messageHandler'
import {POST_MESSAGE, CURRENT_COMPONENT, AML_STATUS} from '../../../constants'
import {getLocalTheme} from '../../../helpers/storageHelper'

const PREFIX = 'AppBar'

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  title: `${PREFIX}-title`,
  brand: `${PREFIX}-brand`
}

const StyledMUIAppBar = styled(MUIAppBar)((
  {
    theme
  }
) => ({
  ...brandTheme.topBar,
  [`& .${classes.toolbar}`]: {
    paddingLeft: `${theme.spacing(1)}`,
    paddingRight: `${theme.spacing(1)}`
  },

  [`& .${classes.title}`]: {
    flex: 1,
    fontSize: '1.2rem',
    marginLeft: `${theme.spacing(2)}`
  },

}))

const brandTheme = getLocalTheme()

class AppBar extends React.Component {
  state = {
    anchorEl: null,
  }

  handleClickMenu = event => {
    this.setState({anchorEl: event.currentTarget})
  }

  handleCloseMenu = target => {
    const {updateTopLevelState} = this.props
    this.setState({anchorEl: null})
    updateTopLevelState({currentComponent: target})
  }

  handleClose = (updateTopLevelState) => {
    const {gtmEvent} = this.props
    postMessage(POST_MESSAGE.HIDE)
    updateTopLevelState({sowDisplayed: false})

    gtmEvent({
      eventAction: 'Closed'
    })
  }

  render () {
    const {
      title,
      amlStatus,
      backButton = false,
      updateTopLevelState,
      debug,
      gtmEvent,
      ...other
    } = this.props

    const {anchorEl} = this.state

    return (
      <StyledMUIAppBar className={classNames(classes.brand)} {...other}>
        <Toolbar className={classes.toolbar}>
          {backButton && <IconButton color='inherit'
            onClick={() => updateTopLevelState({currentComponent: CURRENT_COMPONENT.COUNTDOWN})}
            size='large'>
            <ArrowBack />
          </IconButton>}
          <Typography variant='h1' color='inherit' className={classes.title}>{title}</Typography>
          {amlStatus === AML_STATUS.NOT_BLOCKED &&
            <IconButton color='inherit' onClick={() => this.handleClose(updateTopLevelState)} size='large'>
              <Close />
            </IconButton>
          }
          {debug === '1' &&
            <React.Fragment>
              <IconButton
                color='inherit'
                aria-label='Debug'
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup='true'
                onClick={this.handleClickMenu}
                size='large'>
                <MenuIcon />
              </IconButton>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleCloseMenu}
              >
                <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.COUNTDOWN)}>Countdown</MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.QUESTIONNAIRE)}>Questionnaire</MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.DOCUMENTS)}>Documents</MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.HELP_FAQS)}>Faqs</MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.HELP_UPLOADS)}>Help</MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu(CURRENT_COMPONENT.DEPOSIT_LIMITS)}>Deposit Limits
                </MenuItem>
              </Menu>
            </React.Fragment>
          }
        </Toolbar>
      </StyledMUIAppBar>
    )
  }
}

AppBar.propTypes = {
  position: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  amlStatus: PropTypes.number,
  updateTopLevelState: PropTypes.func.isRequired,
  backButton: PropTypes.bool,
  gtmEvent: PropTypes.func
}

export default (AppBar)
