export const parseIGCDate = (dateString) => {
  // + 'z' tells date that this string is utc time
  return new Date(dateString.slice(-1) === 'Z' ? dateString : dateString + 'Z')
}

export const timeBetween = (start, end, type) => {
  const diffType = {
    'days':     1000 * 60 * 60 * 24,
    'hours':    1000 * 60 * 60,
    'minutes':  1000 * 60,
    'seconds':  1000
  }

  const startMS = start.getTime()
  const endMS = end.getTime()

  const diff = endMS - startMS

  return Math.round(diff / diffType[type])
}
