import React from 'react'
import crypto from 'crypto'
import Parser from 'html-react-parser'
import {Link} from 'react-router-dom'
import domToReact from 'html-react-parser/lib/dom-to-react'
import {GRACE_PERIOD_TYPES, AML_STATUS} from '../constants'
import userNoteData from '../userNoteData.json'

/**
 * Returns a sentence to it's spinal-case equivalent
 * @param {string} str
 */
export function spinalCase (str) {
  return str.toLowerCase().replace(/[^\w\s]/gi, '').split(' ').join('-')
}

export function md5 (str) {
  return crypto.createHash('md5').update(str).digest('hex')
}

export function htmlToReactParser (content) {
  const options = {
    // eslint-disable-next-line
    replace: (domNode) => {
      // do not replace if element has no attributes
      if (!domNode.attribs) return
      if (domNode.attribs && domNode.attribs.href && domNode.attribs.target !== '_blank') {
        return (
          <Link to={domNode.attribs.href} className={domNode.attribs.class}>
            {domToReact(domNode.children, options)}
          </Link>
        )
      } else if (domNode.name === 'b' || domNode.name === 'strong') {
        return (
          <b className='bold'>{domToReact(domNode.children, options)}</b>
        )
      } else if (domNode.name === 'i' || domNode.name === 'em') {
        return (
          <i className='italic'>{domToReact(domNode.children, options)}</i>
        )
      }
    }
  }
  content = content.replace(/&#8217;/g, '\'').replace(/>\s+</g, '><').replace('\n', '')
  return Parser(content, options)
}

export function getSOWType (gracePeriodType, amlStatus, resubmitSOWQ) {
  let type
  const prevType = localStorage.getItem('sowType')

  if (gracePeriodType === GRACE_PERIOD_TYPES.QUESTIONNAIRE ||
    amlStatus === AML_STATUS.SOW_QUESTIONNAIRE || resubmitSOWQ) {
    type = 'questionnaire'
  }

  if (gracePeriodType === GRACE_PERIOD_TYPES.DOCUMENTS || amlStatus === AML_STATUS.SOW_DOCUMENTS) {
    type = 'documents'
  }

  if (gracePeriodType === GRACE_PERIOD_TYPES.FUNDS || amlStatus === AML_STATUS.SO_FUNDS) {
    type = 'funds'
  }

  if (type) {
    localStorage.setItem('sowType', type)
  }

  // use previous type if current type is undefined
  return type || prevType
}

export function getSOWQNote (sowq) {
  const questions = []

  for (let i = 0; i < sowq.length; i += 1) {
    const {SowQuestionId, SowAnswerIds} = sowq[i]

    const {text: questionText, answers} = userNoteData[SowQuestionId]

    let answersWithText = []
    for (let j = 0; j < SowAnswerIds.length; j += 1) {
      const sowAnswerId = SowAnswerIds[j]
      answersWithText.push(`${answers[sowAnswerId]}, AID: ${sowAnswerId}`)
    }

    questions.push(`Question: ${questionText}, QID: ${SowQuestionId}, Answer: ${answersWithText.join(', ')}`)
  }

  return encodeURI(`SOWQ Resubmitted: ${questions.join(' | ')}`)
}
