import React from 'react'
import {styled} from '@mui/material/styles'
import classNames from 'classnames'
import MUIButton from '@mui/material/Button'
import {getLocalTheme} from '../../../helpers/storageHelper'

const PREFIX = 'Button'

const classes = {
  root: `${PREFIX}-root`,
  contained: `${PREFIX}-contained`,
}

const StyledMUIButton = styled(MUIButton)((
  {
    theme
  }
) => ({
  textTransform: 'initial',
  borderRadius: 3,
  boxShadow: theme.shadows[0],
  ...brandTheme.button,

  contained: {
    boxShadow: theme.shadows[0],
    '&:active': {
      boxShadow: theme.shadows[0]
    },
    '&:disabled': brandTheme.buttonDisabled
  },

}))

const brandTheme = getLocalTheme()

function Button (props) {
  const {children, className, ...other} = props

  return (
    <StyledMUIButton
      className={classNames(classes.root, classes.contained, classes.brand, className)}
      {...other}
    >
      {children}
    </StyledMUIButton>
  )
}

export default (Button)
