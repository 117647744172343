import {md5} from '../../../helpers/stringHelper'
import {previewFile} from '../../../helpers/fileHelper'

export async function handleChange (event) {
  event.preventDefault()
  const {documentData: {limit, kycId}, addFileToQueue, name, files, typeId} = this.props
  const newFiles = await Promise.all([...event.target.files]
    .slice(0, limit - (files ? files.size : 0))
    .map(async file => {
      var src = await previewFile(file)
      return {
        kycId, blob: file, status: 'loading', src, extension: `.${file.name.split('.').slice(-1)}`
      }
    }))

  newFiles.forEach(file => {
    let fileHash = md5(`${file.blob.name}${Date.now()}`)
    addFileToQueue(name, fileHash, file, typeId)
  })
}

export function handleDelete (removeFileKey) {
  const {removeFileFromQueue, name} = this.props
  removeFileFromQueue(name, removeFileKey)
}

export function getUploadStatusText () {
  const {i18n, documentData: {limit}, files} = this.props

  if (files) {
    let previewStatus = ''

    if (files.size > 0) {
      previewStatus = i18n.t('pop-up.status.photos-added', {part: files.size, total: limit})
    }

    return previewStatus
  }
}
