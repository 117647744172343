import i18n from 'i18n-js'

export class IGCError extends Error {
  constructor (message) {
    super(message)
    this.name = 'IGCError'
  }
}

export const getErrorMessage = (error) => {
  let errorCode = ''

  if (error instanceof IGCError) {
    // igc error
    const igcError = JSON.parse(error.message)
    console.log(igcError[0].Error)
    errorCode = igcError[0].Error
  } else {
    // api error
    errorCode = error.name
  }

  return i18n.t(`errors.${errorCode.toLowerCase()}`, {defaultValue: i18n.t('errors.general-error')})
  // return errorCode
}
