import {spinalCase} from '../../helpers/stringHelper'
import {KYC_STATUS_ID, KYC_TYPE_DATA, KYC_UPLOAD_STATUS, APPROVAL_TYPES} from '../../constants'

export function parseIgcDocuments (documents, isSOWQ) {
  // Work out doc types according to type name or sow support doc type
  const addDocTypes = (doc) => ({
    ...doc,
    DocType: doc.SowSupportDocType === null
      ? spinalCase(doc.KycTypeName)
      : spinalCase(doc.SowSupportDocType)
  })
  const docs = documents
    .map(addDocTypes)
    .filter(({DocType}) => !['source-of-wealth-questionnaire', 'kyc-approval', 'payment-method']
      .includes(DocType))
    .sort((prev, next) => prev.TypeId - next.TypeId)

  // Create unique array of types
  const uniqueDocTypes = Array.from(new Set(docs.map(({DocType}) => DocType)))

  const {REQUESTED, USER_REQUESTED, APPROVED, PROCESSING} = KYC_STATUS_ID
  const documentRequests = new Map()

  uniqueDocTypes.forEach(docType => {
    let filteredDocs = docs.filter(doc => doc.DocType === docType)
    if (filteredDocs.length > 0) {
      // used to upload doc, upload lane will be closed if not found
      const {KycId, KycTypeId} = ((() => filteredDocs.find(doc => [REQUESTED, USER_REQUESTED]
        .includes(doc.Status)) || {KycId: null})())
      if (KycId) {
        const {TypeId} = filteredDocs[0]

        // used to verify how many docs are uploaded, upload lane will be closed if this number is over doc type limit
        const activeDocs = filteredDocs.filter(doc => [APPROVED, PROCESSING].includes(doc.Status))

        // used to verify how many docs can be uploaded for a certain doc type
        const limit = KYC_TYPE_DATA[docType].limit

        // overall doc type status, verified status should never be shown as that is a backoffice operation,
        // and also rejected should never be shown as rejected docs stay in history forever afaik
        let status = ''
        if (filteredDocs.filter(doc => doc.Status === PROCESSING).length > 0) {
          status = KYC_UPLOAD_STATUS.PROCESSING
        } else if (filteredDocs.filter(doc => [REQUESTED, USER_REQUESTED].includes(doc.Status)).length > 0) {
          status = KYC_UPLOAD_STATUS.INCOMPLETE
        }

        const sowqDocuments = [APPROVAL_TYPES.ID, APPROVAL_TYPES.ADDRESS, APPROVAL_TYPES.PAYMENT]
        // filter out address if AML or Grace Period is not SOWQ
        if (isSOWQ || (!isSOWQ && !sowqDocuments.includes(KycTypeId))) {
          documentRequests.set(docType, {
            kycId: KycId,
            activeDocs,
            limit,
            status,
            TypeId
          })
        }
      }
    }
  })

  return documentRequests
}
