import React from 'react'
import {styled} from '@mui/material/styles'
import i18n from 'i18n-js'

import {CURRENT_COMPONENT} from '../../../constants'

import Link from '@mui/material/Link'
import {Info} from '@mui/icons-material'
import {getLocalTheme} from '../../../helpers/storageHelper'

const PREFIX = 'FAQLink'

const classes = {
  faqLink: `${PREFIX}-faqLink`,
  faqText: `${PREFIX}-faqText`
}

const StyledLink = styled(Link)((
) => ({
  display: 'inline-flex',
  alignItems: 'center',
}))

const StyledText = styled('span')((
  {
    theme
  }
) => ({
  marginLeft: theme.spacing(0.5)
}))

const brandTheme = getLocalTheme()

function FAQLink ({updateTopLevelState, gtmEvent}) {
  let FAQIcon = <Info />
  if (brandTheme.faqIcon) {
    FAQIcon = <img src={brandTheme.faqIcon} alt='info' />
  }

  return (
    <StyledLink
      onClick={() => {
        updateTopLevelState({currentComponent: CURRENT_COMPONENT.HELP_FAQS})
        gtmEvent({eventAction: 'FAQs'})
      }}
      variant='body2' underline='always' className={classes.faqLink}>
      {FAQIcon}
      <StyledText>{i18n.t('faq.link-text')}</StyledText>
    </StyledLink>
  )
}

export default (FAQLink)
