import React, {Component} from 'react'
import {styled} from '@mui/material/styles'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import ErrorIcon from '@mui/icons-material/Error'

const PREFIX = 'ErrorMessage'

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  message: `${PREFIX}-message`
}

const StyledSnackbar = styled(Snackbar)((
  {
    theme
  }
) => ({
  backgroundColor: theme.palette.error.dark,
  marginBottom: `${theme.spacing(2)}`,

  icon: {
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}))

class ErrorMessage extends Component {
  constructor (props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    const {updateAppState} = this.props
    updateAppState('showError', false)
  }

  render () {
    const {open, errorMessage} = this.props

    return (
      <StyledSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={open}
        // autoHideDuration={3000}
        onClose={this.handleClose}
      >
        <SnackbarContent
          className={classes.root}
          message={<span className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {errorMessage}
          </span>}
          action={[
            <IconButton key='close' color='inherit' onClick={this.handleClose} size='large'>
              <ClearIcon />
            </IconButton>
          ]}
        />
      </StyledSnackbar>
    )
  }
}

export default (ErrorMessage)
