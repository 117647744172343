import React from 'react'
import {createRoot} from 'react-dom/client'
import queryString from 'query-string'
import {getBrandTheme} from './helpers/storageHelper'
import TagManager from 'react-gtm-module'

const {location: {search}} = window
const {
  theme,
  loadGTM = true
} = queryString.parse(search, {
  arrayFormat: 'comma',
  parseNumbers: true,
  parseBooleans: true,
})

if (loadGTM) {
  TagManager.initialize({
    gtmId: 'GTM-KS87ZP6',
    dataLayerName: 'SOW_dataLayer',
  })
}

const container = document.getElementById('root')
const root = createRoot(container)

const renderApp = () => {
  const App = require('./modules/App/containers/App').default
  root.render(<App />)
}

if (theme) {
  getBrandTheme(theme).then(() => {
    // importing App after promise ensures theme is saved before any App functions run
    renderApp()
  })
} else {
  renderApp()
}
