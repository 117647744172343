/**
 * Execute an array of promises, one at a time, and return result of each in an array.
 * @callback promiseCallback
 * @param {Array.<promiseCallback>} array with results of promise
 * @return {Object[]} Returns an array with the result of all promises
 */
export function All (promises) {
  let chain = Promise.resolve([])
  promises.forEach(promise => {
    chain = chain.then(results => promise().then(result => {
      return Promise.resolve([...results, result])
    }))
  })
  return chain
}

/**
 * Wraps a promise to delay execution
 * @callback promiseCallback
 * @param {number} delay Number of time to delay in milliseconds
 * @param {promiseCallback} promise the promise to execute on time expiry
 */
export function Wait (delay, promise) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(promise())
    }, delay)
  })
}
