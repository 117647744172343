/**
 * Promisifys file preview in javascript.
 * @param {Object} file source file from form upload
 * @return {promise} Returns the file as a data url resolved within a promise
 */
export function previewFile (file) {
  return new Promise((resolve) => {
    let reader = new FileReader() // eslint-disable-line no-undef
    reader.onload = (reader) => {
      resolve(reader.target.result || reader.currentTarget.result)
    }
    reader.onerror = resolve
    reader.readAsDataURL(file)
  })
}
