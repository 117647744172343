import {
  AML_STATUS,
  AML_GTM_CATEGORY,
  GP_GTM_CATEGORY
} from '../constants'

export function getGTMCategory (amlStatus, gracePeriodType) {
  if (amlStatus !== AML_STATUS.NOT_BLOCKED) {
    return AML_GTM_CATEGORY[amlStatus]
  } else if (gracePeriodType) {
    return GP_GTM_CATEGORY[gracePeriodType]
  }

  return 'NOT_BLOCKED'
}
