import {All} from '../../../helpers/promiseHelper'
import {parseIgcDocuments} from '../../Common/actions'
import {getErrorMessage} from '../../../handlers/errorHandler'
import {AML_STATUS, APPROVAL_STATUSES, GRACE_PERIOD_TYPES, LICENSES} from '../../../constants'

export function handleChange (event) {
  const propName = event.target.id
  const propValue = event.target.value
  this.setState(prevState => ({
    formFields: {...prevState.formFields, [propName]: propValue}
  }))
}

export async function loadDocuments (isSOWQ, license) {
  const {sowApiDataLayer, handleError} = this.props

  try {
    const [igcDocuments, kycStatus] = await Promise.all([sowApiDataLayer.User.kyc(), sowApiDataLayer.User.getKycStatus()])

    // get Documents Requests with a Kyc Status that is not set to Approved
    const documents = igcDocuments.filter(d => {
      const { StatusId } = kycStatus.find(k => k.ApprovalTypeId === d.KycTypeId) || {}
      return StatusId !== APPROVAL_STATUSES.APPROVED
    })

    const documentRequests = parseIgcDocuments(documents, isSOWQ)

    // if SOWQ and ID, Address are not pending -> submitted = true
    const hasPhotoID = documentRequests.has('photo-id')
    const hasAddress = documentRequests.has('proof-of-address')

    this.setState(prevState => {
      const {submitted: prevSubmitted} = prevState

      let sowqSubmitted = (!hasPhotoID && !hasAddress)
      if (isSOWQ && license === LICENSES.SGA) {
        sowqSubmitted = true
      }

      return {
        documentRequests,
        submitted: (isSOWQ) ? sowqSubmitted : prevSubmitted
      }
    })
  } catch (error) {
    handleError(getErrorMessage(error))
  }
}

export async function handleFileUpload () {
  const filesToUpload = []
  const idFilesToUpload = []
  const {fileQueue} = this.state
  const {sowApiDataLayer, handleError, amlStatus, gracePeriodType, license} = this.props
  fileQueue.forEach((files) => {
    files.forEach((file) => {
      const {src, extension, kycId} = file

      let fileData = {
        data: src.substr(src.indexOf(',') + 1),
        KYCID: kycId,
        extension
      }

      filesToUpload.push(fileData)
    })
  })

  this.setState({
    uploading: true
  })

  try {
    await All([
      ...filesToUpload.map(file => () => sowApiDataLayer.User.kycUpload(file)),
      ...idFilesToUpload.map(file => () => sowApiDataLayer.User.kycUploadWithIdDocInfo(file))
    ])
  } catch (error) {
    handleError(getErrorMessage(error))
  }

  this.setState({
    fileQueue: new Map(),
    uploading: false,
    submitted: true
  })

  const isSOWQ = amlStatus === AML_STATUS.SOW_QUESTIONNAIRE || gracePeriodType === GRACE_PERIOD_TYPES.QUESTIONNAIRE
  setTimeout(() => this.loadDocuments(isSOWQ, license), 2000)
}

export function addFileToQueue (name, fileHash, file, typeId) {
  const {fileQueue} = this.state
  let queue = null

  if (fileQueue.has(name)) {
    queue = fileQueue.set(name, fileQueue.get(name).set(fileHash, {...file, typeId}))
  } else {
    const fileMap = new Map()
    fileMap.set(fileHash, {...file, typeId})
    queue = fileQueue.set(name, fileMap)
  }

  this.setState({
    fileQueue: queue,
    canSubmit: true
  })
}

export async function removeFileFromQueue (typeId, fileHash) {
  const {fileQueue} = this.state

  if (fileQueue.has(typeId)) {
    fileQueue.get(typeId).delete(fileHash)

    const submittedFiles = Array.from(fileQueue.keys()).filter(document => {
      return fileQueue.get(document).size > 0
    })

    this.setState({
      fileQueue,
      canSubmit: submittedFiles.length > 0
    })
  }
}
