import React from 'react'

import {ThemeProvider as MuiThemeProvider, createTheme} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import {blue} from '@mui/material/colors'
import {getLocalTheme} from '../../../helpers/storageHelper'

const defaultColor = 'rgba(0, 0, 0, 0.87)'

const brandTheme = getLocalTheme()

const theme = createTheme({
  palette: {
    primary: {
      main: brandTheme.primaryColor || blue[500]
    },
    secondary: {
      main: brandTheme.primaryColor || blue[500]
    },
    background: {
      default: brandTheme.backgroundColor || 'rgba(200, 200, 200, 0.75)'
    },
    text: {
      // primary: brandTheme.bodyText || defaultColor
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '1.8rem'
    },
    h2: {
      fontSize: '1.6rem'
    },
    h3: {
      fontSize: '1.2rem'
    },
    body1: {
      color: brandTheme.bodyText || defaultColor
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: brandTheme.headerColor || defaultColor
        },
        h2: {
          color: brandTheme.headerColor || defaultColor
        },
        h3: {
          color: brandTheme.headerColor || defaultColor
        },
        body2: {
          color: brandTheme.headerColor || defaultColor
        },
        paragraph: {
          margin: '16px 0'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: brandTheme.faqs.backgroundColor || 'white'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          color: brandTheme.linkColor || blue[500],
          cursor: 'pointer'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: 'initial',
          boxShadow: 'none',

          '&:active': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: brandTheme.select.color
        },
        select: {
          '&.Mui-focused': {
            backgroundColor: 'transparent'
          },
          '&:not([multiple]) option': {
            backgroundColor: brandTheme.select.backgroundColor
          }
        },
        icon: {
          color: 'black',
          right: 15
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          width: '97%',
          margin: '20px auto 30px'
        },
        track: {
          height: 8
        },
        thumb: {
          height: 20,
          width: 20
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: (brandTheme.select.borderRadius !== undefined) ? brandTheme.select.borderRadius : 4,
            borderColor: brandTheme.select.borderColor
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: brandTheme.headerColor
        }
      }
    },
  },

})

function Theme ({children}) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        {children}
      </CssBaseline>
    </MuiThemeProvider>
  )
}

export default Theme
