import React, {Component} from 'react'
import {styled} from '@mui/material/styles'
import i18n from 'i18n-js'

import Wrapper from '../../Common/components/Wrapper'
import AppBar from '../../Common/components/AppBar'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {getLocalTheme} from '../../../helpers/storageHelper'
import {htmlToReactParser} from '../../../helpers/stringHelper'

const QuestionTypography = styled(Typography)((
) => ({
  color: brandTheme.faqs.questionColor || brandTheme.headerColor,
}))

const AnswerTypography = styled(Typography)((
) => ({
  color: brandTheme.faqs.answerColor || brandTheme.bodyText,
}))

const brandTheme = getLocalTheme()

class FAQs extends Component {
  constructor (props) {
    super(props)

    this.state = {
      current: -1
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (index) {
    const {current} = this.state
    const _index = (current === index) ? -1 : index
    this.setState({current: _index})
  }

  render () {
    const {amlStatus, updateTopLevelState, debug, gtmEvent} = this.props
    const {current} = this.state

    const faqList = i18n.t('faq-list')
    const faqs = Object.keys(faqList)

    return (
      <React.Fragment>
        <AppBar
          position='sticky'
          title={i18n.t('faq.title')}
          amlStatus={amlStatus}
          updateTopLevelState={updateTopLevelState}
          backButton={true}
          debug={debug}
          gtmEvent={gtmEvent}
        />
        <Wrapper>
          <React.Fragment>
            {faqs.map((key, index) => {
              return <React.Fragment key={index}>
                <Accordion expanded={current === index} onChange={() => { this.handleClick(index) }}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <QuestionTypography>
                      {i18n.t(`faq-list.${key}.question`)}
                    </QuestionTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AnswerTypography>
                      {htmlToReactParser(i18n.t(`faq-list.${key}.answer`))}
                    </AnswerTypography>
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            })}
          </React.Fragment>
        </Wrapper>
      </React.Fragment>
    )
  }
}

export default (FAQs)
