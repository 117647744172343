import React from 'react'
import i18n from 'i18n-js'

import {postMessage} from '../../../handlers/messageHandler'

import Link from '@mui/material/Link'
import {AML_STATUS, POST_MESSAGE, LICENSES} from '../../../constants'

function WithdrawFunds ({amlStatus, gtmEvent, license}) {
  if (amlStatus !== AML_STATUS.NOT_BLOCKED && license !== LICENSES.MGA) {
    return <React.Fragment>
      <Link
        onClick={() => {
          postMessage(POST_MESSAGE.CHAT)
          gtmEvent({eventAction: 'Chat'})
        }}
        variant='body2' underline='always'>
        {i18n.t('withdraw-funds')}
      </Link><br/>
    </React.Fragment>
  }

  return null
}

export default WithdrawFunds
