import React, {Component} from 'react'
import {styled} from '@mui/material/styles'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import classNames from 'classnames'

import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {getLocalTheme} from '../../../helpers/storageHelper'

const PREFIX = 'SelectField'

const classes = {
  label: `${PREFIX}-label`,
  select: `${PREFIX}-select`,
  hide: `${PREFIX}-hide`
}

const StyledFormControl = styled(FormControl)((
  {
    theme
  }
) => ({
  label: {
    background: backgroundColor || 'white',
    paddingRight: `${theme.spacing(1)}`
  },

  select: {
    marginBottom: `${theme.spacing(2)}`
  },

  hide: {
    display: 'none'
  }
}))

const brandTheme = getLocalTheme()
const {mainContainer: {backgroundColor}} = brandTheme

// TODO: implement one common SelectField (Questionnaire/Documents)
class SelectField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: ''
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    const {options: {Id}, handleFieldSubmit, preSelectData} = this.props

    if (preSelectData) {
      this.setState({
        value: preSelectData
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: [preSelectData]
      })
    }
  }

  handleChange = (event) => {
    const {handleFieldSubmit} = this.props

    this.setState({
      value: event.target.value
    }, () => {
      const {value} = this.state
      handleFieldSubmit(value)
    })
  }

  render () {
    const {name, options, preSelectData} = this.props
    const label = 'Select Document'
    return (
      <StyledFormControl variant='outlined' className={classNames(classes.select, {[classes.hide]: preSelectData})}>
        <InputLabel className={classes.label} ref={ref => { this.InputLabelRef = ref }} htmlFor={name}>
          {label}
        </InputLabel>
        <Select
          native
          value={this.state.value}
          onChange={this.handleChange}
          IconComponent={ExpandMore}
          input={
            <OutlinedInput
              // labelWidth={label.length * 7.5}
              name={name}
              id={name}
            />
          }
        >
          <option value=''></option>
          {Array.from(options).map(([key]) => {
            return <option value={key} key={key}>{i18n.t(`documents-sections.${key}.title`)}</option>
          })}
        </Select>
      </StyledFormControl>
    )
  }
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  options: PropTypes.object.isRequired
}

export default (SelectField)
